.swiper.HeroSliderBox {
	width: 100%;
	height: 80vh;
	max-height: 80vh;
}

.swiper.HeroSliderBox .swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;
	max-width: 100%;
	/* Center slide text vertically */
	display: flex;
	justify-content: center;
	align-items: center;
}

.swiper.HeroSliderBox .swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.swiper.HeroSliderBox .swiper-pagination-bullet {
	background-color: var(--color_04);
}

/* response swiper  */
@media (max-width: 768px) {
	.swiper.HeroSliderBox {
		width: 100%;
		height: 40vh;
		max-height: 50vw;
	}
}
