.contact-box .content {
	box-shadow: 0px 0px 16px #0000000f;
	border-radius: 10px;
}

.contact-box .content section div {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: start;
	align-items: start;
	gap: 6px;
	margin-bottom: 12px;
}

.contact-box .content section h6 {
	font-size: 18px;
	font-weight: 400;
	color: var(--color_03);
}

.contact-box .content section div textarea,
.contact-box .content section div input {
	all: unset;
	display: block;
	width: 100%;
	border: 1px solid #969696;
	border-radius: 4px;
	padding: 0 16px;
	height: 56px;
	box-sizing: border-box;
}

.contact-box .content section div textarea::placeholder,
.contact-box .content section div input::placeholder {
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0px;
	color: #969696;
}

.contact-box .content section button {
	font-size: 18px;
	font-weight: 400;
}

.contact-box .content .info ul h4 {
	font-size: 24px;
	font-weight: 600;
	color: var(--color_02);
}

.contact-box .content .info ul li {
	font-size: 24px;
	font-weight: 500;
}
