.ant-dropdown {
	z-index: 23333;
}
.ant-dropdown .ant-dropdown-menu {
	padding: 12px 0;
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	-o-border-radius: 2px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content {
	font-size: 18px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-icon {
	min-width: 18px;
	margin-inline-end: 6px;
	font-size: 18px;
}
