.swiper.NewsSlider {
	width: 100%;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: 1280px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.NewsSlider .swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;

	display: flex;
	justify-content: center;
	align-items: center;
}

.NewsSlider .swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.swiper.NewsSlider .swiper-pagination-bullet {
	background-color: var(--color_02);
}

.swiper .swiper-button-prev::after,
.swiper .swiper-button-prev::after {
	content: "next";
}

.swiper .swiper-button-next::after {
	content: "prev";
}
.swiper.NewsSlider .swiper-button-next {
	left: 0px !important;
}
.swiper.NewsSlider .swiper-button-prev {
	right: 0px !important;
}
