.swiper.ServicesSlider {
  width: 100%;
  height: 100%;
    margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ServicesSlider .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .ServicesSlider .swiper-wrapper {

  display: flex;
  justify-content: center;
  align-items: center;
} */

.ServicesSlider .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
 .swiper.ServicesSlider .swiper-pagination-bullet{
background-color: var(--color_02);

}

/*
.swiper .swiper-button-prev,
.swiper .swiper-button-next {
  width: 50px;
  height: 50px;
  background-color: var(--default-color-01);
  border-radius: 50%;
  bottom: 95px;
  top: auto;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  border: 1px solid #95c0b452;
  transition: var(--main-transition-01);
  user-select: none;
} */





.swiper .swiper-button-prev::after,
.swiper .swiper-button-next::after {
  content: "";
  font-family: swiper-icons;
  font-size: 20px !important;
  font-weight: bold;
  /* margin-right: -3px; */
    /* color: var(--color_03); */
  transition: var(--main-transition-01);

}

/* .swiper.ServicesSlider .swiper-button-prev::after {
  content: "next";
  margin-right: -35px !important;

}

.swiper.ServicesSlider .swiper-button-next::after {
  content: "prev";
  margin-right: 35px !important;

} */
